@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-width: thin;
  scrollbar-color: #d3d3d3 transparent !important;
}

h2 {
  @apply text-lg font-bold;
}

h3 {
  @apply text-base font-bold;
}

/* antd */
.anticon {
  /* Overwrite Authing Web Guard Style */
  @apply inline-flex;
}

.ant-upload-drag {
  @apply !bg-[#fafcff];
}

.ant-steps-finish-icon svg {
  @apply align-baseline;
}

.ant-steps-item-title {
  @apply !leading-5;
}

.ant-steps-item-active .ant-steps-item-title {
  @apply !text-[#1677ff];
}

.ant-table-placeholder .ant-table-cell {
  @apply !border-b-transparent;
}

.ant-form-item .ant-form-item-label > label .ant-form-item-tooltip {
  @apply cursor-default !important;
}

.ant-tour-footer {
  @apply !hidden;
}

/* Authing */
.authing-g2-render-module-normal {
  @apply static transform-none;
}

.g2-view-container {
  @apply min-h-fit;
}

.g2-view-header {
  @apply hidden;
}

.authing-ant-btn-primary {
  @apply bg-[#215ae5];
}

::-webkit-scrollbar {
  @apply h-1.5 w-1.5;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-corner {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply rounded-[3px] bg-[#d3d3d3];
}
